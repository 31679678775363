import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import Hero from '../components/hero-post'

class NotFound extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const data = { title: '404', description: 'page not found' }

    return (
      <Layout location={this.props.location}>
        <Helmet>
          <title>404 - {siteTitle}</title>
        </Helmet>
        <Hero data={data} />
        <section className="page container" style={{textAlign: "center"}}>
            <p>Sorry 😔 — I couldn't find what you were looking for.</p>
            <p>You can check out my <a href="/blog">blog</a>, though! Maybe you can find what you're looking for there. 😊</p>
            <p>Also, my <a href="/">homepage</a>, of course.</p>
        </section>
      </Layout>
    )
  }
}

export default NotFound

export const pageQuery = graphql`
  query NotFoundQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`